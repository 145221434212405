
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import AuthPhoneVerification from '@/components/pages/auth/AuthPhoneVerification.vue'

export default defineComponent({
  components: {
    TmButton,
    AuthPhoneVerification,
    TmModal,
  },
  setup() {
    const code = ref<string>('')

    return {
      code,
    }
  },
})
